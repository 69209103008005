import style from "./Account.module.scss";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import {
	selectProfile,
	login as loginProfile,
} from "../../reducer/profileSlice";
import { generateNonce, hasPath } from "../../utils/helper";

export default function AccountInfo() {
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("account." + key);
	let navigate = useNavigate();
	const profile = useAppSelector(selectProfile);

	const dispatch = useAppDispatch();

	useEffect(() => {
		async function initializeLoginDetails() {
			const nonce = generateNonce();
			const result: any = await api.accountMgt.loginDetails(nonce).catch(Error);
			if (!result) return;
			const data = result.data;
			const isMissing = !hasPath(data, ["email", "id", "isGASet"]);
			if (isMissing) return alerting("error", "data is missing");
			dispatch(
				loginProfile({
					email: data.email,
					userId: data.id,
					isGASet: data.isGASet,
				})
			);
			// dispatch(setIsGASet(result.data.isGASet));
		}
		initializeLoginDetails();
	}, [dispatch]);

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("accountInfo")}</div>
			<div className={style["li-container"]}>
				<div className={style["li-label"]}>{t("password")}</div>
				<div className={style["li-value"]}>
					<TextField
						disabled
						type="password"
						defaultValue="12345678"
						variant="filled"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-button"]}>
				<Button
					variant="contained"
					onClick={() => navigate("/account/forgetPassword")}
				>
					{t("changePassword")}
				</Button>
			</div>
			<div className={style["li-container"]}>
				<div className={style["li-label"]}>{t("email")}</div>
				<div className={style["li-value"]}>
					<TextField
						disabled
						value={profile.email}
						variant="filled"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			{/* <div className={style["li-button"]}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/account/updateEmail")}
				>
					{t("updateEmail")}
				</Button>
			</div> */}
		</div>
	);
}
