import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
interface ComponentProps {
	children: JSX.Element[] | JSX.Element;
	className?: string;
	enableAccountLogin?: boolean;
}
export default function LoginContainer(props: ComponentProps) {
	const { t } = useTranslation();
	const { enableAccountLogin } = props;
	const className = styles.loginContainer + " " + (props.className || "");
	return (
		<Container
			disableGutters
			className={className}
			sx={{
				display: "flex",
			}}
		>
			{enableAccountLogin && (
				<nav>
					<Link to="/accountLogin" className="clickable">
						<PersonRoundedIcon />
						{t("visitor.accountLogin")}
					</Link>
				</nav>
			)}
			{props.children}
		</Container>
	);
}
