import {
	configureStore,
	combineReducers,
	ThunkAction,
	Action,
} from "@reduxjs/toolkit";
import alerterReducer from "./alerterSlice";
import visitorReducer from "./visitorSlice";
import profileReducer from "./profileSlice";
import stuffReducer from "./stuffSlice";
import { createLogger } from "redux-logger";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { isDev } from "../utils/config";

const persistConfig = {
	key: "root",
	version: 1.01,
	storage, //save in localStorage
	whitelist: ["profile", "visitor"], // profile and visitor will be persisted
};

const reducers = combineReducers({
	alerter: alerterReducer,
	visitor: visitorReducer,
	profile: profileReducer,
	stuff: stuffReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

//localStorage.setItem('showReduxLog',true)
const showLog = localStorage.getItem("showReduxLog") === "true";
const logger = createLogger({
	predicate: () => isDev && showLog,
});

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(logger),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
