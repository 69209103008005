import lodashHas from 'lodash/has';

import en from '../i18nSetting/en';
import zh_cn from '../i18nSetting/zh_cn';
import zh_hk from '../i18nSetting/zh_hk';

export function generateNonce() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

const hasPath = (obj: any, paths: string | Array<string>) => {
	if (Array.isArray(paths)) {
		return paths.every((path) => lodashHas(obj, path));
	} else {
		return lodashHas(obj, paths);
	}
};

const getErrorMessage = (
	translateKey: keyof typeof zh_hk.errorMessage,
	params?: Record<string, string | number>
) => {
	const translationObj = { en, zh_hk, zh_cn }["zh_hk"];

	let foundMessage = translationObj.errorMessage[translateKey];

	if (params) {
		Object.entries(params).forEach(
			([key, value]) =>
				(foundMessage = foundMessage.replaceAll(
					"%{" + key + "}",
					String(value)
				))
		);
	}

	return foundMessage;
};

export { hasPath, getErrorMessage };
