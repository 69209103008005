import { useState, useEffect } from "react";
import LoginContainer from "../../components/LoginContainer";
import LoginBox from "../../components/LoginBox";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import api from "../../api";
import { useAppSelector, useAppDispatch } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { setToken, setEmail } from "../../reducer/profileSlice";
import { selectProfile } from "../../reducer/profileSlice";
import styles from "./verifyEmail.module.scss";
import { hasPath } from "../../utils/helper";

export default function VerifyEmailGuard() {
	let navigate = useNavigate();
	let location: any = useLocation();
	const isLost = !hasPath(location, ["state.email", "state.password"]);
	useEffect(() => {
		if (isLost) return navigate("/register");
	}, [isLost, navigate]);

	if (isLost) return <></>;
	return <VerifyEmail />;
}
function VerifyEmail() {
	const [inputCode, setInputCode] = useState("");
	const profile = useAppSelector(selectProfile);
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	let location: any = useLocation();
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("visitor." + key);
	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputCode(e.target.value);
	};
	const onSubmit = async () => {
		const params = { code: inputCode, accessToken: profile.token };
		const result: any = await api.accountMgt.verifyEmail(params).catch(Error);
		if (!result) return;
		navigate("/accountLogin");
		dispatch(alerting("success", "Verify Email Success"));
	};
	async function sendAgain() {
		const verifyParams = location.state;
		const result: any = await api.accountMgt
			.register(verifyParams)
			.catch(Error);
		if (!result) return;
		const isMissing = !hasPath(result, ["data.tempAccessToken", "data.code"]);
		if (isMissing) return dispatch(alerting("error", "data missing"));
		dispatch(setToken(result.data.tempAccessToken));
		dispatch(setEmail(verifyParams.email));
		dispatch(alerting("success", "sent verify code again"));
	}
	return (
		<LoginContainer>
			<LoginBox className={styles.loginBox} hasPrev>
				<h3>{t("verify")}</h3>
				<div>{t("enterVerifyCode")}</div>
				<div className={styles.codeInput}>
					<input value={inputCode} onChange={onChangeInput} />
				</div>
				<div onClick={sendAgain} className={`${styles.sendAgain} clickable`}>
					{t("sendAgain")}
				</div>
				<div>{profile.email}</div>
				<Button onClick={onSubmit}>{t("submit")}</Button>
			</LoginBox>
		</LoginContainer>
	);
}
