import React from "react";
import style from "./Account.module.scss";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";

export default function AuthStatus() {
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("account." + key);
	let navigate = useNavigate();
	const { isGASet } = useAppSelector(selectProfile);

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("googleAuthentication")}</div>
			<div className={style["li-container"]}>
				<div className={style["li-label"]}>{t("status")}</div>
			</div>
			<div className={style["li-container"]}>
				{isGASet ? (
					<div className={`${style["li-label"]} ${style["li-enabled"]}`}>
						{t("enabled")}
					</div>
				) : (
					<div className={`${style["li-label"]} ${style["li-disabled"]}`}>
						{t("disabled")}
					</div>
				)}
			</div>
			<div className={`${style["li-button"]} ${style["li-button-auth"]}`}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/ga/request")}
				>
					{isGASet ? t("rebind") : t("request")}
				</Button>
			</div>
		</div>
	);
}
