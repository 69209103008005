import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface StuffState {
	// lastLoginAppId: number;
	appUuid: string;
}

export interface IPayload {
	// appId: number;
	appUuid: string;
}

const initialState: StuffState = {
	// lastLoginAppId: -1,
	appUuid: "",
};

export const stuffSlice = createSlice({
	name: "stuff",
	initialState,
	reducers: {
		setLastLoginAppId: (state, action: PayloadAction<IPayload>) => {
			// state.lastLoginAppId = action.payload.appId;
			state.appUuid = action.payload.appUuid;
		},
	},
});

export const { setLastLoginAppId } = stuffSlice.actions;

export const selectStuff = (state: RootState) => state.stuff;

export default stuffSlice.reducer;
