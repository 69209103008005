import { AxiosError } from 'axios';

import { alerting, setRedirectTo } from '../reducer/alerterSlice';
import { logout as logoutProfile } from '../reducer/profileSlice';
import { store } from '../reducer/store';
import { returnCodeDict } from './constant';
import { getErrorMessage } from './helper';

type errorFace = AxiosError & {
	response?: {
		status?: number;
		message?: string;
	};
};
interface ResponseFace {
	data: {
		returnCode: number;
		message: string;
	};
}
const kickCodeStr = [
	"login token expired",
	"invalid login token",
	"login token type invalid",
].join(",");
export function appError(response: ResponseFace) {
	console.warn(response.data);
	const returnCode = response.data.returnCode as number;
	const message = response.data.message as string;
	const dbMessage = message.toLowerCase();
	const dictMessage = (returnCodeDict[returnCode] || "").toLowerCase();
	if (kickCodeStr.includes(dbMessage) || kickCodeStr.includes(dictMessage)) {
		const { isLogged } = store.getState().profile;
		if (isLogged) store.dispatch(setRedirectTo("/accountLogin"));
		store.dispatch(logoutProfile());
	}
	store.dispatch(alerting("warning", message));
	return false;
}
export default async function errorHandler(error: errorFace) {
	if (error.message === "Network Error" && !window.navigator.onLine) {
		store.dispatch(
			alerting("error", getErrorMessage("no_internet_connection"))
		);
		return false;
	}

	if (error.code === "ECONNABORTED") {
		store.dispatch(alerting("error", getErrorMessage("network_timeout")));
		return false;
	}

	const response = error.response;
	if (!response) return Promise.reject(error);
	if (!response.status) return Promise.reject(error);
	const statusCode = response.status;
	console.warn(response);
	if (response.message) {
		store.dispatch(alerting("error", "Error " + response.message));
	} else {
		store.dispatch(
			alerting(
				"error",
				getErrorMessage("general_network_error", { statusCode })
			)
		);
	}

	if (statusCode === 401) {
		// logout user
	}

	if (statusCode >= 500) {
		// show server error
	}

	if (statusCode === 400) {
		// show bad request error
	}
	return false;
}
