import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import style from "./Account.module.scss";
import { useNavigate } from "react-router-dom";
import { generateNonce } from "../../utils/helper";
import api from "../../api";
import { useAppDispatch } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import { useState } from "react";

const initUserInputs = {
	oldPassword: "",
	newPassword: "",
	confirmNewPassword: "",
};

export default function ChangePassword() {
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("account." + key);
	let navigate = useNavigate();
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const dispatch = useAppDispatch();

	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};

	const onSubmit = async () => {
		if (
			!userInputs.newPassword ||
			!userInputs.confirmNewPassword ||
			!userInputs.oldPassword
		)
			return dispatch(alerting("error", "Password Cannot be Empty"));
		if (userInputs.newPassword !== userInputs.confirmNewPassword)
			return dispatch(alerting("error", "Password Not Match"));
		const nonce = generateNonce();
		const result: any = await api.accountMgt
			.changePassword({
				nonce,
				oldPassword: userInputs.oldPassword,
				newPassword: userInputs.newPassword,
			})
			.catch(Error);
		if (!result) return;
		dispatch(alerting("success", "Change Password Success"));
	};

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("forgetPassword")}</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("oldPassword")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						name="oldPassword"
						variant="outlined"
						type="password"
						onChange={setInputData}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("newPassword")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						name="newPassword"
						variant="outlined"
						type="password"
						onChange={setInputData}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("confirmNewPassword")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						name="confirmNewPassword"
						variant="outlined"
						type="password"
						onChange={setInputData}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={`${style["li-button"]} ${style["li-button-long"]}`}>
				<Button color="primary" variant="contained" onClick={onSubmit}>
					{t("submit")}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/account/info")}
				>
					{t("back")}
				</Button>
			</div>
		</div>
	);
}
