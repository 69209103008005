import { useState } from "react";
import React from "react";
import style from "./Account.module.scss";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import { alerting } from "../../reducer/alerterSlice";
import api from "../../api";

export default function UpdateEmail() {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const [inputCode, setInputCode] = useState("");
	const profile = useAppSelector(selectProfile);
	const dispatch = useAppDispatch();

	const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputCode(e.target.value);
	};

	const onVerifyEmailSubmit = async () => {
		const params = { code: inputCode, accessToken: profile.token };
		const result: any = await api.accountMgt.verifyEmail(params).catch(Error);
		if (!result) return;
		dispatch(alerting("success", "Verify Email Success"));
	};

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("account.updateEmail")}</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("account.email")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						disabled
						defaultValue={profile.email}
						variant="filled"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("account.emailVerifyCode")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						id="filled"
						variant="outlined"
						value={inputCode}
						onChange={onChangeInput}
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
				<div className={`${style["li-button"]} ${style["side-button"]}`}>
					<Button variant="contained" onClick={onVerifyEmailSubmit}>
						{t("account.sendVerifyCode")}
					</Button>
				</div>
			</div>
			<div className={style["li-container"]}>
				<div className={`${style["li-label"]} ${style["li-label-long"]}`}>
					{t("account.googleAuthentication")}
				</div>
				<div className={style["li-value"]}>
					<TextField
						id="filled"
						variant="outlined"
						inputProps={{
							style: {
								padding: 5,
							},
						}}
					/>
				</div>
			</div>
			<div className={`${style["li-button"]} ${style["li-button-long"]}`}>
				<Button color="primary" variant="contained">
					{t("account.submit")}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => navigate("/account/info")}
				>
					{t("account.back")}
				</Button>
			</div>
		</div>
	);
}
