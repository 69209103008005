import { useEffect } from "react";
import api from "../api";
import { useAppDispatch } from "../reducer/hooks";
import { alerting } from "../reducer/alerterSlice";
import { setToken } from "../reducer/profileSlice";
import { generateNonce } from "../utils/helper";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Redirect() {
	let navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const accessToken = searchParams.get("accessToken");
	useEffect(() => {
		async function verifyToken() {
			dispatch(setToken(accessToken as string));
			const nonce = generateNonce();
			const result: any = await api.accountMgt.loginDetails(nonce).catch(Error);
			const toUrl = result ? "/account/info" : "/accountLogin";
			navigate(toUrl);
		}
		verifyToken();
	}, [accessToken, dispatch, navigate]);
	if (!accessToken || accessToken.length === 0) {
		dispatch(alerting("error", "invalid token"));
		return <></>;
	}
	return <>redirecting</>;
}
