import { useState } from "react";
import LoginContainer from "../../components/LoginContainer";
import LoginBox from "../../components/LoginBox";
import { useTranslation } from "react-i18next";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import TextfieldIcon from "../../components/TextfieldIcon";
import Button from "../../components/Button";
import api from "../../api";
import { useAppDispatch } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import { setToken, setEmail } from "../../reducer/profileSlice";
import { generateNonce, hasPath } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

const initUserInputs = {
	email: "",
	password: "",
	confirmPassword: "",
};
export default function Register() {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};
	const validInputs = () => {
		return userInputs.password === userInputs.confirmPassword;
	};
	const popsError = (text: string) => dispatch(alerting("error", text));
	const onSubmit = async () => {
		if (!validInputs())
			return popsError("password and confirm password is not the same");
		const nonce = generateNonce();
		const verifyParams = {
			nonce,
			email: userInputs.email,
			password: userInputs.password,
		};
		const result: any = await api.accountMgt
			.register(verifyParams)
			.catch(Error);
		if (!result) return;
		const isMissing = !hasPath(result, ["data.tempAccessToken", "data.code"]);
		if (isMissing) return popsError("data missing");
		dispatch(setToken(result.data.tempAccessToken));
		dispatch(setEmail(userInputs.email));
		navigate("/verifyEmail", { state: verifyParams });
	};
	return (
		<LoginContainer>
			<LoginBox hasPrev>
				<h3>{t("Sign Up")}</h3>
				<TextfieldIcon
					name="email"
					placeholder="Email"
					value={userInputs.email}
					icon={<AlternateEmailRoundedIcon />}
					onChange={setInputData}
				/>
				<TextfieldIcon
					name="password"
					type="password"
					placeholder="Password"
					value={userInputs.password}
					icon={<KeyRoundedIcon />}
					onChange={setInputData}
				/>
				<TextfieldIcon
					name="confirmPassword"
					type="password"
					placeholder="Confirm Password"
					value={userInputs.confirmPassword}
					icon={<KeyRoundedIcon />}
					onChange={setInputData}
				/>
				<Button onClick={onSubmit}>Submit</Button>
			</LoginBox>
		</LoginContainer>
	);
}
