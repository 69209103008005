//only import once in App.tsx, and dispatch(open(...)) anywhere
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { close, selectAlerter, setRedirectTo } from "../reducer/alerterSlice";
import { useAppSelector, useAppDispatch } from "../reducer/hooks";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { isDev } from "../utils/config";
export default function Alerter() {
	const state = useAppSelector(selectAlerter);
	const redirectTo = state.redirectTo;
	const dispatch = useAppDispatch();
	let navigate = useNavigate();
	useEffect(() => {
		if (isDev) window.document.title = "Dev/Acc User Auth Account";
	}, []);
	useEffect(() => {
		if (redirectTo && redirectTo.length > 0) {
			navigate(redirectTo);
			dispatch(setRedirectTo(""));
		}
	}, [redirectTo, dispatch, navigate]);
	return (
		<Snackbar
			open={state.open}
			autoHideDuration={10000}
			onClose={() => dispatch(close())}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert
				onClose={() => dispatch(close())}
				severity={state.severity}
				sx={{ width: "100%" }}
			>
				{state.text}
			</Alert>
		</Snackbar>
	);
}
