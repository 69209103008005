import { useState } from "react";
import LoginContainer from "../../components/LoginContainer";
import LoginBox from "../../components/LoginBox";
import { useTranslation } from "react-i18next";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import TextfieldIcon from "../../components/TextfieldIcon";
import Button from "../../components/Button";
import api from "../../api";
import { useAppDispatch } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import { setToken } from "../../reducer/profileSlice";
import { generateNonce, hasPath } from "../../utils/helper";
import { isDev } from "../../utils/config";
import { useNavigate } from "react-router-dom";

const initUserInputs = {
	email: "",
};

export default function ForgetPassword() {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};

	const onSubmit = async () => {
		const nonce = generateNonce();
		const result: any = await api.accountMgt
			.resetPasswordRequest({
				nonce,
				email: userInputs.email,
			})
			.catch(Error);
		if (!result) return;
		const isMissing = !hasPath(result, "data.tempAccessToken");
		if (isMissing)
			return dispatch(alerting("error", "tempAccessToken is missing"));
		if (isDev) alert("Dev only, code= " + result.data.code);
		dispatch(setToken(result.data.tempAccessToken));
		navigate("/resetPassword");
	};

	return (
		<LoginContainer>
			<LoginBox hasPrev>
				<h3>{t("visitor.forgotPassword")}</h3>
				<TextfieldIcon
					name="email"
					placeholder="Email"
					value={userInputs.email}
					icon={<KeyRoundedIcon />}
					onChange={setInputData}
				/>

				<Button onClick={onSubmit}>Submit</Button>
			</LoginBox>
		</LoginContainer>
	);
}
