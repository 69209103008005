import { useState } from "react";
import LoginContainer from "../../components/LoginContainer";
import LoginBox from "../../components/LoginBox";
import { useTranslation } from "react-i18next";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import TextfieldIcon from "../../components/TextfieldIcon";
import Button from "../../components/Button";
import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { alerting } from "../../reducer/alerterSlice";
import { selectStuff } from "../../reducer/stuffSlice";
import { logout as logoutProfile } from "../../reducer/profileSlice";
import { generateNonce } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import styles from "./ResetPassword.module.scss";

const initUserInputs = {
	code: "",
	password: "",
	confirmPassword: "",
};

export default function ResetPassword() {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	// const { lastLoginAppId } = useAppSelector(selectStuff);
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("visitor." + key);
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};
	const onSubmit = async () => {
		if (userInputs.password !== userInputs.confirmPassword)
			return alerting("error", "password and confirm password is different");
		const nonce = generateNonce();
		const result: any = await api.accountMgt
			.resetPassword({
				nonce,
				verificationCode: userInputs.code,
				newPassword: userInputs.password,
			})
			.catch(Error);
		if (!result) return;
		dispatch(logoutProfile());
		const text = `${t("resetPassword")} ${t("success")}`;
		dispatch(alerting("success", text));
		// const isAppLogin = lastLoginAppId > 0;
		// const link = isAppLogin
		// 	? "/login?appId=" + lastLoginAppId
		// 	: "/accountLogin";
		// navigate(link);
	};

	return (
		<LoginContainer>
			<LoginBox hasPrev className={styles.loginBox}>
				<h3>{t("resetPassword")}</h3>
				<input name="stop-auto-fill" type="password" />
				<div className={styles.codeInput}>
					<input
						name="code"
						value={userInputs.code}
						onChange={setInputData}
						placeholder={t("code")}
						autoComplete="off"
					/>
				</div>
				<TextfieldIcon
					name="password"
					type="password"
					placeholder={t("password")}
					value={userInputs.password}
					icon={<KeyRoundedIcon />}
					onChange={setInputData}
					autoComplete="off"
				/>
				<TextfieldIcon
					name="confirmPassword"
					type="password"
					placeholder={t("confirmPassword")}
					value={userInputs.confirmPassword}
					icon={<KeyRoundedIcon />}
					onChange={setInputData}
					autoComplete="off"
				/>

				<Button onClick={onSubmit}>{t("submit")}</Button>
			</LoginBox>
		</LoginContainer>
	);
}
