import { useState, useEffect } from "react";
import LoginContainer from "../../components/LoginContainer";
import LoginBox from "../../components/LoginBox";
import { useTranslation } from "react-i18next";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import TextfieldIcon from "../../components/TextfieldIcon";
import Button from "../../components/Button";
import api from "../../api";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import {
	selectVisitor,
	setEmail,
	setRemember,
} from "../../reducer/visitorSlice";
import { setLastLoginAppId } from "../../reducer/stuffSlice";
import { alerting } from "../../reducer/alerterSlice";
import { setToken } from "../../reducer/profileSlice";
import { generateNonce, hasPath } from "../../utils/helper";
import { useNavigate, Link } from "react-router-dom";
import styles from "./index.module.scss";
import { ACC_UUID } from "../../utils/config";

const initUserInputs = {
	email: "",
	password: "",
	remember: false,
};

export default function AccountLogin() {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();

	// useEffect(() => {
	// 	dispatch(setLastLoginAppId(-1));
	// }, [dispatch]);
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("visitor." + key);
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const visitorStore = useAppSelector(selectVisitor);
	useEffect(() => {
		const { remember, email } = visitorStore;
		if (remember) setUserInputs((inputs) => ({ ...inputs, email, remember }));
	}, [visitorStore.remember]);
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const isCheckBox = e.target.type === "checkbox";
		const value = isCheckBox ? e.target.checked : e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};
	const configRemember = () => {
		if (userInputs.remember) {
			dispatch(setRemember(true));
			dispatch(setEmail(userInputs.email));
		} else {
			dispatch(setRemember(false));
			dispatch(setEmail(""));
		}
	};
	const onSubmit = async () => {
		const appUuid = ACC_UUID || "";
		const nonce = generateNonce();
		const result: any = await api.account
			.login({
				nonce,
				appId: 0,
				appUuid: appUuid,
				email: userInputs.email,
				password: userInputs.password,
			})
			.catch(Error);
		configRemember();
		if (!result) return;
		const isMissing = !hasPath(result, "data.accessToken");
		if (isMissing) return dispatch(alerting("error", "data missing"));
		dispatch(setToken(result.data.accessToken));
		navigate("/account/info");
	};
	return (
		<LoginContainer>
			<LoginBox>
				<h3>{t("accountLogin")}</h3>
				<form
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
				>
					<div className={styles.inputGroup}>
						<TextfieldIcon
							name="email"
							placeholder={t("login") + " " + t("email")}
							value={userInputs.email}
							icon={<AlternateEmailRoundedIcon />}
							onChange={setInputData}
						/>
						<TextfieldIcon
							name="password"
							type="password"
							placeholder={t("password")}
							value={userInputs.password}
							icon={<KeyRoundedIcon />}
							onChange={setInputData}
						/>
						<div>
							<input
								name="remember"
								type="checkbox"
								checked={userInputs.remember ? true : false}
								onChange={setInputData}
							/>{" "}
							{t("rememberMeNextTime")}
						</div>
					</div>
					<Button type="submit">{t("confirm")}</Button>
				</form>
				<div className={styles.helper}>
					<Link to="/register">{t("notRegistered")}?</Link>
					<Link to="/forgetPassword">{t("forgotPassword")}?</Link>
				</div>
			</LoginBox>
		</LoginContainer>
	);
}
