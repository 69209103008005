import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";

import api from "../../api";
import Button from "../../components/Button";
import LoginBox from "../../components/LoginBox";
import LoginContainer from "../../components/LoginContainer";
import TextfieldIcon from "../../components/TextfieldIcon";
import { alerting } from "../../reducer/alerterSlice";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import { setLastLoginAppId } from "../../reducer/stuffSlice";
import {
	selectVisitor,
	setEmail,
	setRemember,
} from "../../reducer/visitorSlice";
import { DEBUG_REDIRECT_URL, isDev } from "../../utils/config";
import { generateNonce, hasPath } from "../../utils/helper";
import styles from "./index.module.scss";

const initAppInfo = {
	name: "",
	isGALoginEnabled: false,
	iconUrl: "",
	status: "loading",
};
const initialAppAuthInfo = {
	appId: 0,
	appUuid: "",
};
const nonce = generateNonce();
export default function AppLogin() {
	const [appInfo, setAppInfo] = useState(initAppInfo);
	const [appOrAppUuid, setAppOrAppUuid] = useState(initialAppAuthInfo);

	let [searchParams] = useSearchParams();
	const popsError = (text: string) => dispatch(alerting("error", text));
	// const appId = Number(searchParams.get("appId"));
	// const appUuid = window.location.href.split("/").pop()!;
	const acceptParams = ["appId", "appUuid"];
	const getParamsName = window.location.href.split("?").pop()!;
	const isAccept = acceptParams.includes(getParamsName.split("=").shift()!);
	const appKey = getParamsName.split("=").shift()!;

	let appUuid = "";
	if (isAccept) {
		appUuid = window.location.href.split("=").pop()!;
	}

	const dispatch = useAppDispatch();
	useEffect(() => {
		setAppOrAppUuid((pre) => ({ ...pre, [appKey]: appUuid }));

		const fetchAppInfo = async () => {
			const result: any = await api.account
				.getAppInfo({ /* appId,*/ appUuid, nonce })
				.catch(Error);
			if (!result) return;
			const isMissing = !(
				hasPath(result, ["data"]) &&
				hasPath(result.data, ["name", "isGALoginEnabled", "iconUrl"])
			);
			if (isMissing) return dispatch(alerting("error", "data is missing"));
			setAppInfo({ ...result.data, status: "finish" });
			dispatch(setLastLoginAppId({ /* appId,*/ appUuid }));
		};
		fetchAppInfo();
	}, [dispatch, appUuid]);
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("visitor." + key);
	const initUserInputs = {
		email: "",
		password: "",
		ga: "",
		remember: false,
	};
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const visitorStore = useAppSelector(selectVisitor);
	useEffect(() => {
		const { remember, email } = visitorStore;
		if (remember) setUserInputs((inputs) => ({ ...inputs, email, remember }));
	}, [visitorStore.remember]);
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const isCheckBox = e.target.type === "checkbox";
		const value = isCheckBox ? e.target.checked : e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};
	const configRemember = () => {
		if (userInputs.remember) {
			dispatch(setRemember(true));
			dispatch(setEmail(userInputs.email));
		} else {
			dispatch(setRemember(false));
			dispatch(setEmail(""));
		}
	};
	const onSubmit = async () => {
		const loginParams = {
			nonce,
			appId: Number(appOrAppUuid.appId),
			appUuid: appOrAppUuid.appUuid,
			email: userInputs.email,
			password: userInputs.password,
			gaCode: userInputs.ga || undefined,
		};

		if (!appInfo.isGALoginEnabled) {
			delete loginParams.gaCode;
		}

		const result: any = await api.account.login(loginParams).catch(Error);
		configRemember();
		if (!result) return;
		const isMissing = !hasPath(result, [
			"data.accessToken",
			"data.redirectUrl",
		]);
		if (isMissing) return popsError("data missing");

		redirectTo(result.data.redirectUrl, result.data.accessToken);
	};

	function redirectTo(link: string, accessToken: string) {
		window.location.href = link;
		if (isDev && DEBUG_REDIRECT_URL) {
			window.location.href = `${DEBUG_REDIRECT_URL}${accessToken}`;
		}
	}

	if (appInfo.status === "loading") return <></>;
	const hasIconUrl = appInfo.iconUrl.length > 0;
	return (
		<LoginContainer enableAccountLogin>
			<LoginBox>
				<h3 className={styles.heading}>{t("login")}</h3>
				<div className={styles.appInfo}>
					<p>{hasIconUrl && <img src={appInfo.iconUrl} alt="app-icon" />}</p>
					<div>{appInfo.name}</div>
				</div>
				<form
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
				>
					<div className={styles.inputGroup}>
						<TextfieldIcon
							name="email"
							placeholder={t("login") + " " + t("email")}
							value={userInputs.email}
							icon={<AlternateEmailRoundedIcon />}
							onChange={setInputData}
						/>
						<TextfieldIcon
							name="password"
							type="password"
							placeholder={t("password")}
							value={userInputs.password}
							icon={<KeyRoundedIcon />}
							onChange={setInputData}
						/>
						{appInfo.isGALoginEnabled && (
							<TextfieldIcon
								name="ga"
								type="number"
								placeholder={t("ga")}
								value={userInputs.ga}
								icon={<KeyRoundedIcon />}
								onChange={setInputData}
							/>
						)}
						<div>
							<input
								name="remember"
								type="checkbox"
								checked={userInputs.remember}
								onChange={setInputData}
							/>{" "}
							{t("rememberMeNextTime")}
						</div>
					</div>
					<Button type="submit">{t("confirm")}</Button>
				</form>
				<div className={styles.helper}>
					<Link to="/register">{t("notRegistered")}?</Link>
					<Link to="/forgetPassword">{t("forgotPassword")}?</Link>
				</div>
			</LoginBox>
		</LoginContainer>
	);
}
