import { Routes, Route, Navigate } from "react-router-dom";
import AccountLogin from "./view/accountLogin";
import AppLogin from "./view/appLogin";
import Register from "./view/register";
import VerifyEmail from "./view/verifyEmail";
import ForgotPassword from "./view/forgotPassword";
import PersistentDrawerLeft from "./components/PersistentDrawerLeft";
import AccountInfo from "./view/account/AccountInfo";
import UpdateEmail from "./view/account/UpdateEmail";
import ChangePassword from "./view/account/ChangePassword";
import ResetPassword from "./view/resetPassword";
import AuthStatus from "./view/account/AuthStatus";
import AuthRequest from "./view/account/AuthRequest";
import CssBaseline from "@mui/material/CssBaseline";
import Alerter from "./components/Alerter";
import Redirect from "./components/Redirect";
import "./styles/general.module.scss";

function App() {
	return (
		<div className="App">
			<CssBaseline />
			<Alerter />
			<Routes>
				<Route path="register" element={<Register />} />
				<Route path="verifyEmail" element={<VerifyEmail />} />
				<Route path="accountLogin" element={<AccountLogin />} />
				<Route path="login" element={<AppLogin />} />
				<Route path="forgetPassword" element={<ForgotPassword />} />
				<Route path="resetPassword" element={<ResetPassword />} />
				<Route path="redirect" element={<Redirect />} />
				<Route
					path="account/info"
					element={
						<PersistentDrawerLeft>
							<AccountInfo />
						</PersistentDrawerLeft>
					}
				/>
				<Route
					path="account/updateEmail"
					element={
						<PersistentDrawerLeft>
							<UpdateEmail />
						</PersistentDrawerLeft>
					}
				/>
				<Route
					path="account/forgetPassword"
					element={
						<PersistentDrawerLeft>
							<ChangePassword />
						</PersistentDrawerLeft>
					}
				/>
				<Route
					path="ga/status"
					element={
						<PersistentDrawerLeft>
							<AuthStatus />
						</PersistentDrawerLeft>
					}
				/>
				<Route
					path="ga/request"
					element={
						<PersistentDrawerLeft>
							<AuthRequest />
						</PersistentDrawerLeft>
					}
				/>
				<Route path="*" element={<Navigate replace to="/accountLogin" />} />
			</Routes>
		</div>
	);
}

export default App;
