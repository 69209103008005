import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
export default function TextFieldIcon(props: any) {
	const { name, type, placeholder, icon, helperText, value, onChange } = props;
	return (
		<TextField
			focused
			name={name}
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			sx={{
				p: 1.5,
				"& .MuiSvgIcon-root,& .MuiFormHelperText-root, & .MuiInput-input": {
					color: "white",
				},
				"& .MuiInputBase-root:after": {
					borderBottom: "2px solid white",
				},
			}}
			InputProps={{
				endAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
			}}
			helperText={helperText}
			variant="standard"
		/>
	);
}
