import getAxiosInstance from './axios';

const host = process.env.REACT_APP_AUTH_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "account");
interface loginFace {
	nonce: string;
	appId: number;
	appUuid: string;
	email: string;
	password: string;
	gaCode?: string;
}
interface appInfoFace {
	appUuid: string;
	// appId: number;
	nonce: string;
}

const apiList = {
	getAppInfo: (params: appInfoFace) =>
		api({
			url: "app/" + params.appUuid,
			params: { nonce: params.nonce },
		}),
	login: (data: loginFace) => api.post("loginWithGA", data),
	logout: () => api.post("logout"),
};
export default apiList;
