import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface VisitorState {
	email: string;
	remember: boolean;
}

const initialState: VisitorState = {
	email: "",
	remember: false,
};

export const visitorSlice = createSlice({
	name: "visitor",
	initialState,
	reducers: {
		setRemember: (state, action: PayloadAction<boolean>) => {
			state.remember = action.payload;
		},
		setEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
	},
});

export const { setRemember, setEmail } = visitorSlice.actions;

export const selectVisitor = (state: RootState) => state.visitor;

export default visitorSlice.reducer;
