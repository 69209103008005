import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface LoginFace {
	email: string;
	userId: number;
	isGASet: boolean;
}
export interface ProfileState {
	token: string;
	email: string;
	userId: number;
	appId: number;
	isLogged: boolean;
	isGASet: boolean;
}

const initialState: ProfileState = {
	token: "",
	email: "",
	userId: -1,
	appId: -1,
	isLogged: false,
	isGASet: false,
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		setEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
		setIsLogged: (state, action: PayloadAction<boolean>) => {
			state.isLogged = action.payload;
		},
		setAppId: (state, action: PayloadAction<number>) => {
			state.appId = action.payload;
		},
		setIsGASet: (state, action: PayloadAction<boolean>) => {
			state.isGASet = action.payload;
		},
		login: (state, action: PayloadAction<LoginFace>) => {
			state.isLogged = true;
			const { email, userId, isGASet } = action.payload;
			state.email = email;
			state.userId = userId;
			state.isGASet = isGASet;
		},
		logout: (state) => {
			state.token = "";
			state.email = "";
			state.userId = -1;
			state.isLogged = false;
		},
	},
});

export const {
	setToken,
	setEmail,
	setIsLogged,
	setAppId,
	setIsGASet,
	logout,
	login,
} = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
