import styles from "./index.module.scss";
import Box from "@mui/material/Box";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useNavigate } from "react-router-dom";

interface ComponentProps {
	children: JSX.Element[] | JSX.Element;
	className?: string;
	hasPrev?: boolean;
}
export default function LoginBox(props: ComponentProps) {
	const className = styles.rootLoginBox + " " + props.className;
	let navigate = useNavigate();
	const backButton = props.hasPrev && (
		<i className={styles.prevButton} onClick={() => navigate(-1)}>
			<ArrowBackIosRoundedIcon />
		</i>
	);
	return (
		<Box className={className}>
			{backButton}
			<Box className={styles.loginBox}>{props.children}</Box>
		</Box>
	);
}
