import React, { useState } from "react";
import style from "./Account.module.scss";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../reducer/hooks";
import api from "../../api";
import { alerting } from "../../reducer/alerterSlice";
import { generateNonce } from "../../utils/helper";
import { setIsGASet, selectProfile } from "../../reducer/profileSlice";

const initUserInputs = {
	gaCode: "",
};

export default function AuthRequest() {
	const { t: trans } = useTranslation();
	const t = (key: string) => trans("account." + key);
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [userInputs, setUserInputs] = useState(initUserInputs);
	const { isGASet } = useAppSelector(selectProfile);
	const setInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
		const key = e.target.name;
		const value = e.target.value;
		setUserInputs((data) => ({ ...data, [key]: value }));
	};

	const onSetupGASubmit = async () => {
		const nonce = generateNonce();
		const result: any = await api.accountMgt.setupGA({ nonce }).catch(Error);
		if (!result) return;
		dispatch(alerting("success", "Google Authentication Sent"));
		setCurrentPage(2);
	};

	const onConfirmGASubmit = async () => {
		const nonce = generateNonce();
		const result: any = await api.accountMgt
			.confirmGA({
				nonce,
				gaCode: userInputs.gaCode,
			})
			.catch(Error);
		if (!result) return;
		dispatch(alerting("success", "Authentication Confirmed"));
		dispatch(setIsGASet(true));
		navigate("/ga/status");
	};

	return (
		<div className={style["content-wrap"]}>
			<div className={style["title"]}>{t("googleAuthentication")}</div>
			{/* <div className={style["li-container"]}>
                <div className={`${style["li-label"]} ${style["li-label-fit-content"]}`}>
                    {t("enterAccountPassword")}
                </div>
                <div className={style["li-value"]}>
                    <TextField
                        // disabled
                        id="filled"
                        type="password"
                        // defaultValue="Hello World"
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                    />
                </div>
            </div> */}
			{currentPage === 1 && (
				<div className={style["li-container"]}>
					<div
						className={`${style["li-label"]} ${style["li-label-fit-content"]}`}
					>
						{isGASet ? t("rebindQRCodeToEmail") : t("gaQRCodeToEmail")}
					</div>
					<div className={`${style["li-button"]} ${style["li-button-role"]}`}>
						<Button variant="contained" onClick={onSetupGASubmit}>
							{t("send")}
						</Button>
					</div>
				</div>
			)}
			{currentPage === 2 && (
				<>
					<div className={style["li-container"]}>
						<div
							className={`${style["li-label"]} ${style["li-label-fit-content"]}`}
						>
							{t("enterYourGa")}
						</div>
						<div className={`${style["li-value"]} ${style["li-button-role"]}`}>
							<TextField
								name="gaCode"
								type="text"
								variant="outlined"
								onChange={setInputData}
								inputProps={{
									style: {
										padding: 5,
									},
								}}
							/>
						</div>
					</div>
					<div className={`${style["li-button"]} ${style["li-button-auth"]}`}>
						<Button variant="contained" onClick={onConfirmGASubmit}>
							{t("send")}
						</Button>
					</div>
				</>
			)}
		</div>
	);
}
