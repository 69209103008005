import getAxiosInstance from "./axios";
const host = process.env.REACT_APP_ACCOUNT_MANAGEMENT_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "accountMgt");
interface RegisterFace {
	nonce: string;
	email: string;
	password: string;
}
interface verifyEmailFace {
	accessToken: string;
	code: string;
}

interface verifyEmailFace {
	accessToken: string;
	code: string;
}

interface resetPasswordRequestFace {
	nonce: string;
	email: string;
}

interface resetPasswordFace {
	nonce: string;
	verificationCode: string;
	newPassword: string;
}

interface changePasswordFace {
	nonce: string;
	oldPassword: string;
	newPassword: string;
}

interface confirmGAFace {
	nonce: string;
	gaCode: string;
}

interface setupGAFace {
	nonce: string;
}

const apiList = {
	register: (data: RegisterFace) => api.post("register", data),
	verifyEmail: (params: verifyEmailFace) => api({ url: "verifyEmail", params }),
	resetPasswordRequest: (data: resetPasswordRequestFace) =>
		api.post("resetPasswordRequest", data),
	resetPassword: (data: resetPasswordFace) => api.post("resetPassword", data),
	changePassword: (data: changePasswordFace) =>
		api.post("changePassword", data),
	setupGA: (data: setupGAFace) => api.post("setupGA", data),
	confirmGA: (data: confirmGAFace) => api.post("confirmGA", data),
	loginDetails: (nonce: string) => api.get("loginDetails", { params: nonce }),
};
export default apiList;
